@import '~antd/dist/antd.css';

body {
  font-family: Roboto, sans-serif;
  overflow-y: overlay;
}

/* ShareEmail */
.success .ant-modal-content {
  border-radius: 1rem;
  background-color: #2ecc71;
}

/* MenuSideBar */
.ant-drawer-content {
  background-color: #fafafade !important;
}

/* MenuSideBar fontsize on filters */
@media (width: 1080px) and (height: 1920px) {
  .ant-select-item{
    font-size: 18px!important;
  }
}  
.App {
  height: 100%;
}

#root {
 height: 100%;
}

.loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 140px;
}

.search-mobile-wrap{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,.4);
  z-index: 2;
  top: 0;
}

.filter-mobile-wrap{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,.4);
  z-index: 2;
  top: 0;
}

.ant-select-item-option {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.ant-select-item-option-content {
  order: 2;
  /* margin-left: 19px; */
}
.ant-select-item-option-selected > .ant-select-item-option-content {
  font-weight: 500 !important;
  /* margin-left: unset; */
}

.ant-select-item-option-state {
  order: 1;
  margin-right: 5px;
}

.anticon-check svg path{
  fill: darkslategray !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e4e4e4 !important;
}